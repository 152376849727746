<template>
    <section id="description-list-alignment">
        <b-row class="match-height">
            <b-col cols="12">
                    <b-row>
                        <b-col md="6" xl="4" v-for="item in items" :key="item.id">
                            <b-card :img-src="getImage(item.image)" img-top img-alt="card img"
                            :title=item.title  class="mb-3">
                                <b-card-text>
                                    {{ item.sub_description }}
                                </b-card-text>
                                <b-row style="text-align: center;">
                                    <b-col md="12">
                                        <a :href="goTOweb(item.id)" target="_blank">
                                            {{ $t("Details") }}
                                        </a>
                                    </b-col>
                                    <b-col md="6">
                                        <b-card-text>
                                    <small class="text-muted">{{ $t("date of publication") }} : {{ shortenDate(item.created_at) }}</small>
                                </b-card-text>
                                    </b-col>
                                    <b-col md="6">
                                        <b-card-text>
                                    <small class="text-muted">{{ $t("published by") }} : {{ shortenDate(item.user.full_name) }}</small>
                                </b-card-text>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
            </b-col>
        </b-row>
    </section>
</template>
  
<script>
import {
    BRow, BCol, BCard, BCardTitle, BButton, BCardText, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { filesUrl, Url } from "@/main.js";
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
    BFormRadioGroup, BFormRadio, BFormGroup, BLink
} from 'bootstrap-vue'
import { codeCollapseTypes } from './code'
export default {
    components: {
        BRow,
        BCol, Url,
        BCard, BButton,
        BCardTitle, BLink,
        BCardText,
        BCardHeader, AppCollapseItem, AppCollapse, BCardCode,
        BCardBody, BFormRadioGroup, BFormRadio, BFormGroup
    },
    data() {
        return {
            items: [],
            issues: [],
            articles: [],
            perPage: 12,
            currentPage: 0,
        }
    },
    mounted() {
        this.getNews()
    },
    methods: {
        async getNews() {
            await fetch(
                `${Url}news/clint?take=1000&skip=${this.currentPage - 1}&is_journal=true`,//
                {
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    this.items = [];
                    this.items = data.items;
                });
        },
        async getIssue(id) {
            await fetch(
                `${Url}archive/issue/${id}`,
                {
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    this.issues = [];
                    this.issues = data.items;
                });
        },
        getImage(path) {
            return `${filesUrl}/${path}`;
        },
        shortenDate(date) {
      let newDate = date.split("");
      newDate = newDate.slice(0, 10);
      newDate = newDate.join("");
      return newDate;
    },
    goTOweb(id) {
      return  "https://nuc.edu.iq/blog/"+id;
    },
    },
}
</script>
  